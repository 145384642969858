import React from "react";
import {
  stateHasData,
  stateHasError,
  FetchState,
} from "@openstax/ts-utils/fetch";
import { SyncExportScores } from "../SyncExportScores";
import syncIcon from "../../../assets/icons/sync.svg";
import builderIcon from "../../../assets/icons/builder.svg";
import { SubContent, StyledSidebarNav } from "./styles";
import useMatchMobileQuery from "../../../utils/reactUtils";
import { SubScopeItem, SubScopeList } from "../../types";
import { Loader } from "@openstax/ui-components";
import { AssignmentManagementStates } from "../../hooks/grades";

export default function Sidebar({
  subScope,
  setSubScope,
  subScopeCounts,
  navBarIsVisible,
  editView,
  assignmentManagementStates,
  subScopes,
}: {
  subScope: "support" | SubScopeItem;
  setSubScope: React.Dispatch<"support" | SubScopeItem>;
  subScopeCounts: { [key: string]: number };
  navBarIsVisible: boolean;
  editView?: boolean;
  assignmentManagementStates?: AssignmentManagementStates;
  subScopes: FetchState<SubScopeList, string>;
}) {
  const isMobile = useMatchMobileQuery();
  const totalSubScopeCounts = React.useMemo(() => {
    return Object.values(subScopeCounts).reduce(
      (total, value) => total + value,
      0
    );
  }, [subScopeCounts]);
  const selectedTitle = (length: number) =>
    `${length} item${length > 1 ? "s" : ""} selected`;
  const subScopeAriaTitle = (html: string, itemCount: number) => {
    const title =
      new DOMParser()
        .parseFromString(html, "text/html")
        .body.textContent?.replace(/\s\s+/g, " ")
        .trim() || "";
    return `${title} ${itemCount > 0 ? `- ${selectedTitle(itemCount)}` : ""}`;
  };

  return (
    <StyledSidebarNav
      isMobile={isMobile}
      actionBarIsVisible={subScope !== "support"}
      navBarIsVisible={navBarIsVisible}
      id="sidebar-nav"
    >
      {({ navIsCollapsed, setNavIsCollapsed }) => (
        <>
          <header>
            {editView && assignmentManagementStates ? (
              <>
                <h1 aria-label="Grade sync">
                  <button
                    {...(!navIsCollapsed && { tabIndex: -1 })}
                    onClick={() => {
                      setNavIsCollapsed(!navIsCollapsed);
                    }}
                    aria-label={
                      navIsCollapsed
                        ? "Click to view Grade Sync"
                        : "Click to collapse navigation"
                    }
                    aria-controls="sidebar-nav"
                  >
                    <img src={syncIcon} alt="" />
                    <span>{navIsCollapsed ? "Grades" : "Grade sync"}</span>
                  </button>
                </h1>
                {!navIsCollapsed ? (
                  <SyncExportScores
                    assignmentManagementStates={assignmentManagementStates}
                  />
                ) : null}
              </>
            ) : null}
            <h1
              aria-label={`Assignment Builder ${
                isMobile && totalSubScopeCounts
                  ? ` - ${totalSubScopeCounts} items selected`
                  : ""
              }`}
            >
              <button
                {...(!navIsCollapsed && { tabIndex: -1 })}
                onClick={() => {
                  setNavIsCollapsed(!navIsCollapsed);
                }}
                aria-label={
                  navIsCollapsed
                    ? "Click to view Assignment Builder"
                    : "Click to collapse navigation"
                }
                aria-controls="sidebar-nav"
              >
                <img src={builderIcon} alt="" />
                <span>{navIsCollapsed ? "Builder" : "Assignment builder"}</span>
                {navIsCollapsed && totalSubScopeCounts ? (
                  <small
                    aria-hidden="true"
                    className={`${totalSubScopeCounts > 99 ? "wide" : ""}`}
                  >
                    {Math.min(totalSubScopeCounts, 99)}
                    {totalSubScopeCounts > 99 ? <sup>+</sup> : null}
                  </small>
                ) : null}
              </button>
            </h1>
          </header>
          <SubContent>
            {stateHasError(subScopes) ? (
              <strong>{subScopes.error}</strong>
            ) : null}
            {!navIsCollapsed ? (
              <ol>
                {stateHasData(subScopes) ? (
                  subScopes.data.map((item) => (
                    <li key={item.subScope.orn}>
                      <button
                        className={`transparent${
                          subScope === item ? " selected" : ""
                        }`}
                        aria-current={subScope === item ? "page" : "false"}
                        aria-label={subScopeAriaTitle(
                          item.subScope.title,
                          subScopeCounts[item.subScope.orn]
                        )}
                        onClick={() => {
                          setSubScope(item);
                          setNavIsCollapsed(isMobile);
                        }}
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html: item.subScope.title,
                          }}
                        />
                        {subScopeCounts[item.subScope.orn] > 0 ? (
                          <small
                            title={selectedTitle(
                              subScopeCounts[item.subScope.orn]
                            )}
                          >
                            {subScopeCounts[item.subScope.orn]}
                          </small>
                        ) : null}
                      </button>
                    </li>
                  ))
                ) : (
                  <Loader delay={500} />
                )}
              </ol>
            ) : null}
          </SubContent>
        </>
      )}
    </StyledSidebarNav>
  );
}
