import React from "react";
import {
  FlattenedActivityStep,
  hasStep,
} from "../../utils/activity-step-utils";
import { ActivityUpdatePayload, ActivityUpdateHandler } from ".";
import { stateHasData } from "@openstax/ts-utils/fetch";
import { applyTopicSortSync } from "@project/lambdas/build/src/services/activityConfigResolver";
import { useOrn } from "../../utils/orn-utils";

export default function useUpdateActivities({
  hasCustomOrder,
  scope,
  setFlatActivities,
}: {
  hasCustomOrder: boolean;
  scope: ReturnType<typeof useOrn>;
  setFlatActivities: React.Dispatch<
    React.SetStateAction<FlattenedActivityStep[]>
  >;
}): ActivityUpdateHandler {
  return React.useCallback((input) => {
    const handler = (
      previous: FlattenedActivityStep[],
      change: ActivityUpdatePayload
    ) => {
      const afterUnselect =
        "unselected" in change
          ? previous.map((previousItem) =>
              hasStep(change.unselected, previousItem)
                ? { ...previousItem, removed: true }
                : previousItem
            )
          : previous;
      const afterSelect =
        "selected" in change
          ? afterUnselect.map((previousItem) =>
              hasStep(change.selected, previousItem)
                ? { ...previousItem, removed: false }
                : previousItem
            )
          : afterUnselect;

      const unattachedNewItems =
        "selected" in change && change.selected
          ? change.selected.filter(
              (selectedItem) =>
                !selectedItem.removed &&
                !hasStep(previous, selectedItem) &&
                !selectedItem.activity.attachedTo
            )
          : [];

      const attachedNewItems =
        "selected" in change && change.selected
          ? change.selected.filter(
              (selectedItem) =>
                !selectedItem.removed &&
                !hasStep(previous, selectedItem) &&
                selectedItem.activity.attachedTo
            )
          : [];

      if (!stateHasData(scope)) {
        throw new Error(
          "should not be possible to update chosen activities before scope loads"
        );
      }

      // if there is a custom order, attached items are added next to their subject steps, while new single
      // elements are added at the end (themselves sorted, but not modifying the sort of stuff above)
      //
      // if there is no custom order, we just throw everything in a list and sort it
      return hasCustomOrder
        ? [
            ...afterSelect.flatMap((selected) => {
              const attachers = attachedNewItems.filter(
                (item) => item.activity.attachedTo === selected.activity.stepId
              );
              return applyTopicSortSync(scope.data, [selected, ...attachers]);
            }),
            ...applyTopicSortSync(scope.data, unattachedNewItems),
          ]
        : applyTopicSortSync(scope.data, [
            ...attachedNewItems,
            ...afterSelect,
            ...unattachedNewItems,
          ]);
    };

    setFlatActivities((previous) => {
      const change = typeof input === "function" ? input(previous) : input;
      return handler(previous, change);
    });
  }, [hasCustomOrder, scope, setFlatActivities]);
}
