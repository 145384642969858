import React from "react";
import {
  fetchLoading,
  fetchIdle,
  FetchState,
  stateHasData,
} from "@openstax/ts-utils/fetch";
import {
  FilterOrnTypes,
  filterResourceContents,
  AnyOrnResource,
} from "@openstax/orn-locator";
import { SubScopeList } from "../../types";
import { useOrn } from "../../utils/orn-utils";

const isAllowedContent = (content: AnyOrnResource) => {
  return (
    content.type === "book:page" &&
    "tocType" in content &&
    content.tocType === "book-content" &&
    ["preface", "intro", "numbered-section"].includes(content.tocTargetType)
  );
};
const isAllowedSubScope = (
  content: AnyOrnResource
): content is FilterOrnTypes<AnyOrnResource, "book:subbook"> => {
  return (
    content.type === "book:subbook" &&
    "tocType" in content &&
    content.tocType === "chapter"
  );
};

const findSubScopes = (
  scope: AnyOrnResource,
  carry: string[] = []
): SubScopeList => {
  const scopeContents: AnyOrnResource[] =
    "contents" in scope ? scope.contents : [];

  const reduced = scopeContents.reduce(
    (result, item: AnyOrnResource) => {
      if (isAllowedContent(item)) {
        return { ...result, carry: [...result.carry, item.orn] };
      }
      if (isAllowedSubScope(item)) {
        const contents = filterResourceContents(item, ["book:page"])
          .filter(isAllowedContent)
          .map((resource) => resource.orn);
        // don't even show subScopes with no items
        if (contents.length < 1) {
          return result;
        }

        return {
          carry: [],
          scopes: [
            ...result.scopes,
            { subScope: item, contents: [...result.carry, ...contents] },
          ],
        };
      }

      const subResults = findSubScopes(item, result.carry);

      if (subResults.length < 1) {
        return result;
      }

      return { carry: [], scopes: [...result.scopes, ...subResults] };
    },
    { carry, scopes: [] } as { carry: string[]; scopes: SubScopeList }
  );

  const tail = reduced.scopes.slice(-1)[0];

  if (reduced.carry.length > 0 && tail) {
    tail.contents.push(...reduced.carry);
  }

  return reduced.scopes;
};

export default function useSubScopes(orn: string | undefined) {
  const loadState = useOrn(orn);
  const [subScopeState, setSubScopeState] = React.useState<
    FetchState<ReturnType<typeof findSubScopes>, string>
  >(orn ? fetchLoading() : fetchIdle());

  React.useEffect(() => {
    setSubScopeState(
      stateHasData(loadState)
        ? { ...loadState, data: findSubScopes(loadState.data) }
        : (loadState as FetchState<ReturnType<typeof findSubScopes>, string>)
    );
  }, [loadState]);

  return subScopeState;
}
