import React from 'react';
import { useApiClient } from "../../api";
import { useSetAppError } from '@openstax/ui-components';
import {
  FetchState,
  fetchLoading,
  fetchIdle,
  fetchSuccess,
  FetchStateType,
  stateHasData,
  stateHasError,
} from "@openstax/ts-utils/fetch";
import {
  StatefulAssignmentResponse,
  LaunchResponse,
  StatefulAssignmentStepResponse,
} from "@project/lambdas/build/src/functions/serviceApi/versions/v0/routes/student";
import { useCompleteTrigger, useProgressTrigger } from "../hooks/usePostMessage";
import { useServices } from "../../core/context/services";
import { makeActivityProgressMessage } from '@openstax/lti/lti-gateway/post-messages';
import ActivityWrapper from './ActivityWrapper';
import ActivityFooter from './ActivityFooter';
import Progress from './Progress';
import { useFrontendConfigValue } from '../../configProvider/use';
import { User } from '@openstax/ts-utils/services/authProvider';
import { useUserMetadata } from '../../user/utils/user-metadata';
import { ActivitiesDisplay } from "../../components/ActivitiesDisplay";
import { Activity } from './Activity';
import { setContentMetadata } from "../../utils/dataLayer";
import { Toasts } from "../../toasts/ToastContext";
import { RecoveryCredentialsOverlay } from './RecoveryCredentials/RecoveryCredentialsOverlay';
import { initializePI } from '../../utils/pulseinsights';

const useLaunchStep = (
  assignment: StatefulAssignmentResponse,
  stepId: string,
  textSizeRef: React.MutableRefObject<number>
) => {
  const apiClient = useApiClient();
  const setAppError = useSetAppError();
  const [state, setState] = React.useState<FetchState<LaunchResponse, string>>(fetchLoading());
  const {launchToken} = useServices();

  React.useEffect(() => {
    setState(previous => fetchLoading(previous));
    apiClient.apiV0LaunchAssignmentStep({
      params: {id: assignment.id, stepId},
      payload: {
        params: {
          // deprecated, JWT parameters to be snake case
          textSize: textSizeRef.current,
          text_size: textSizeRef.current,
        },
        launchToken,
        attempt: assignment.attemptId
      }
    })
      .then(response => response.acceptStatus(200, 201).load())
      .then(response => {
        setState(fetchSuccess(response));
      })
      .catch(setAppError)
    ;
  }, [apiClient, assignment, stepId, setAppError, launchToken, textSizeRef]);

  return state;
};

const useCompleteStep = (
  assignment: StatefulAssignmentResponse,
  stepId: string,
  updateActivityState: (activity: StatefulAssignmentStepResponse) => void
) => {
  const apiClient = useApiClient();
  const setAppError = useSetAppError();
  const [state, setState] = React.useState<FetchState<StatefulAssignmentStepResponse, string>>(fetchIdle());
  const { launchToken } = useServices();

  const onComplete = React.useCallback(() => {
    const currentStep = assignment.activities.find((activity) => activity.stepId === stepId);
    if (currentStep?.state.isComplete) {
      return Promise.resolve();
    }

    setState((previous) => fetchLoading(previous));

    return apiClient.apiV0CompleteAssignmentStep({
      params: { id: assignment.id, stepId },
      payload: {
        launchToken,
        attempt: assignment.attemptId,
      },
    })
      .then(response => response.acceptStatus(200, 201).load())
      .then(response => {
        if (response.assignmentComplete || response.syncPartialScores) {
          window.parent.postMessage(
            makeActivityProgressMessage({
              id: assignment.id.toString(),
              isComplete: response.assignmentComplete,
              syncPartialScores: response.syncPartialScores
            }),
            '*'
          );
        }
        setState(fetchSuccess(response));
        updateActivityState(response);
      })
      .catch(setAppError);
  }, [apiClient, assignment, stepId, setAppError, launchToken, updateActivityState]);

  return [state, onComplete] as const;
};

const useAssignmentState = (assignment: StatefulAssignmentResponse) => {
  // this is used for state updates after the assignment is launched
  const [hash, setHash] = React.useState<{
    [key: string]: StatefulAssignmentStepResponse;
  }>({});

  const list = React.useMemo(() => assignment.activities.reduce(
    (result, activity) => ([
      ...result,
      hash[activity.stepId] || activity
    ])
  , [] as StatefulAssignmentStepResponse[]), [hash, assignment]);

  const updateActivityState = React.useCallback((activity: StatefulAssignmentStepResponse) => {
    setHash(previous => ({...previous, [activity.stepId]: activity}));
  }, [setHash]);

  return [list, updateActivityState] as const;
};

const useAuthToken = () => {
  const authProvider = useServices().authProvider;
  const [state, setState] = React.useState<string | null>();

  React.useEffect(() => {
    authProvider.getAuthToken().then((token) => setState(token));
  }, [authProvider]);

  return state;
};

export const Assignment = ({ assignment, user }: { assignment: StatefulAssignmentResponse; user: User }) => {
  const authProvider = useServices().authProvider;
  const accountsBase = useFrontendConfigValue('accountsBase');
  const textSizeRef = React.useRef<number>(0);


  React.useEffect(() => {
    // there is no specific content but we want to record the scope, so
    // use the scope as the content. can clean up when contentId is not required
    setContentMetadata(assignment.scope, assignment.scope);
  }, [assignment]);

  const [activityStateList, updateActivityState] = useAssignmentState(assignment);
  const numIncomplete = React.useMemo(() =>
    activityStateList.filter((item) => !item.state.isComplete).length, [activityStateList]);
  const [showProgress, setShowProgress] = React.useState(!numIncomplete);
  const initialActivityIndex = activityStateList.findIndex(a => !a.state.isComplete);
  const [currentActivityIndex, setCurrentActivityIndex] = React.useState<number>(
    initialActivityIndex >= 0 ? initialActivityIndex : 0
  );
  const launchState = useLaunchStep(
    assignment,
    activityStateList[currentActivityIndex]?.stepId,
    textSizeRef,
  );

  
  const [userMetadata, saveUserMetadata] = useUserMetadata();
  const authToken = useAuthToken();

  const currentActivityIndexRef = React.useRef<number>(currentActivityIndex);
  currentActivityIndexRef.current = currentActivityIndex;
  const activityStateListRef = React.useRef<typeof activityStateList>(activityStateList);
  activityStateListRef.current = activityStateList;

  const canShowFooter = launchState.type === FetchStateType.SUCCESS && launchState.data.showContinue;

  const shouldShowCredentialsOverlay = React.useMemo(() => (
    userMetadata.type === FetchStateType.SUCCESS &&
      !userMetadata.data.recoveryCredentialsDeclined &&
      userMetadata.data.completedAssignmentsCount &&
      userMetadata.data.completedAssignmentsCount > 0 &&
      'contact_infos' in user &&
      user.contact_infos.length === 0
  ), [userMetadata, user]);

  const [showCredentialsOverlay, setShowCredentialsOverlay] = React.useState<boolean | null>(null);

  React.useEffect(() => {
    if (shouldShowCredentialsOverlay && showCredentialsOverlay !== false) {
      setShowCredentialsOverlay(true);
    }
  }, [showCredentialsOverlay, shouldShowCredentialsOverlay]);

  const goToNextStep = React.useCallback(() => {
    if (currentActivityIndexRef.current === activityStateListRef.current.length - 1) {
      setShowProgress(true);
    } else if (currentActivityIndexRef.current < activityStateListRef.current.length - 1) {
      setCurrentActivityIndex(currentActivityIndexRef.current + 1);
    }
  }, []);
  
  const [completeState, onComplete] = useCompleteStep(
    assignment,
    activityStateList[currentActivityIndex]?.stepId,
    updateActivityState
  );

  const onCompleteAndGoNext = React.useCallback(() => {
    onComplete().then(() => goToNextStep());
  }, [onComplete, goToNextStep]);


  useCompleteTrigger(onCompleteAndGoNext);
  useProgressTrigger(onComplete);

  const goToNextIncomplete = React.useCallback(() => {
    setCurrentActivityIndex(activityStateListRef.current.findIndex((item) => !item.state.isComplete));
    setShowProgress(false);
  }, []);

  React.useEffect(() => {
    if (!numIncomplete) {
      setShowProgress(true);
    }
  }, [numIncomplete]);

  React.useEffect(() => {
    if (userMetadata.type === FetchStateType.SUCCESS) {
      const completedAssignmentsCount = userMetadata.data.completedAssignmentsCount ?? 0;
      initializePI({ completedAssignmentsCount, role: 'student' });
    }
  }, [userMetadata]);

  return <>
    {showCredentialsOverlay && accountsBase.type === FetchStateType.SUCCESS && authToken && authToken.length > 0 ?
      <RecoveryCredentialsOverlay
        authProvider={authProvider}
        setShow={setShowCredentialsOverlay}
        accountsHost={accountsBase.data}
        saveUserMetadata={saveUserMetadata} />
      : null}
    <Toasts />
    <ActivitiesDisplay
      sidebar={activityStateList.map((activity, index) => ({
        onClick: () => {
          setCurrentActivityIndex(index);
          setShowProgress(false);
        },
        id: activity.stepId,
        active: index === currentActivityIndex && !showProgress,
        icon: activity.icon?.url,
        title: activity.title,
        isComplete: activity.state.isComplete,
      }))}
    >
      {showProgress
        ? <Progress
          numIncomplete={numIncomplete}
          numActivities={activityStateListRef.current.length}
          buttonHandler={goToNextIncomplete} />
        : <ActivityWrapper
          loading={launchState.type === FetchStateType.LOADING}
          error={stateHasError(launchState)
            ? launchState.error
            : (stateHasError(completeState) ? completeState.error : '')
          }
        >
          {stateHasData(launchState) && launchState.type === FetchStateType.SUCCESS
            ? <>
              <Activity
                defaultTextSize={textSizeRef.current}
                onChangeTextSize={size => { textSizeRef.current = size; }}
                title={activityStateList[currentActivityIndex].title}
                src={authProvider.getAuthorizedEmbedUrl(launchState.data.launchUrl)}
                isTextResizable={launchState.data.textSize}
                assignment={assignment}
              />
              <ActivityFooter
                canShow={canShowFooter}
                loading={completeState.type === FetchStateType.LOADING}
                isCompleted={activityStateList[currentActivityIndex].state.isComplete}
                onNext={goToNextStep}
                onComplete={onCompleteAndGoNext} />
            </>
            : null}
        </ActivityWrapper>
      }
    </ActivitiesDisplay>
  </>;
};
