import React from "react";
import {
  FlattenedActivityStep,
  stepIsRemoved,
} from "../../utils/activity-step-utils";
import { FetchState, stateHasData } from "@openstax/ts-utils/fetch";
import { SubScopeList, SubScopeItem } from "../../types";

export default function useSubScopeCounts({
  flatActivities,
  subScopes,
  setSubScope,
}: {
  flatActivities: FlattenedActivityStep[];
  subScopes: FetchState<SubScopeList, string>;
  setSubScope: React.Dispatch<"support" | SubScopeItem>;
}) {
  const [firstSelectedResource, setFirstSelectedResource] =
    React.useState<boolean>(false);
  const subScopeCounts = React.useMemo(() => {
    if (!stateHasData(subScopes) || flatActivities.length === 0) {
      return {};
    }

    const countStepsForTopics = (contents: string[]) =>
      flatActivities.reduce(
        // only count steps here that are not "attached to" other steps, meaning they were added
        // via an addon checkbox. not sure this distinction is going to fly forever but it seems ok
        // for now
        (count, step) =>
          stepIsRemoved(step) || step.activity.attachedTo
            ? count
            : Object.keys(step.topics).some((topic) => contents.includes(topic))
            ? count + 1
            : count,
        0
      );

    return subScopes.data.reduce((counts, resource) => {
      counts[resource.subScope.orn] = countStepsForTopics([
        resource.subScope.orn,
        ...resource.contents,
      ]);
      return counts;
    }, {} as { [key: string]: number });
  }, [flatActivities, subScopes]);

  React.useEffect(() => {
    if (stateHasData(subScopes) && !firstSelectedResource) {
      const firstResource = subScopes.data.find(
        (resource) => subScopeCounts[resource.subScope.orn] > 0
      );
      if (firstResource) {
        setSubScope(firstResource);
        setFirstSelectedResource(true);
      }
    }
  }, [subScopes, setSubScope, subScopeCounts, firstSelectedResource]);

  return { subScopeCounts, setFirstSelectedResource };
}
