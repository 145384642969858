import React from 'react';
import styled from 'styled-components';
import { createRoute, makeScreen } from "../../core/services";
import {
  fetchIdle,
  fetchLoading,
  FetchState,
  FetchStateType,
  fetchSuccess,
  stateHasData,
} from "@openstax/ts-utils/fetch";
import { AnyOrnResource } from "@openstax/orn-locator";
import { SelectScopeContent } from "../components/SelectScopeContent";
import { useOrn, useOrns } from "../utils/orn-utils";
import * as UI from '@openstax/ui-components';
import { useApiClient } from '../../api';
import {
  AssignmentCreateResponse,
  AssignmentReadResponse,
  CreateAssignmentStepPayload
} from "@project/lambdas/build/src/functions/serviceApi/versions/v0/routes/instructor/assignments";
import { ResourceGrid } from "../components/ResourceGrid";
import { useFrontendConfigValue } from "../../configProvider/use";
import { renderRouteUrl } from "../../core";
import { launchScreen } from "../../student/screens/Launch";
import { selectActivity } from "../utils/select-activity";
import { useContextMetadata } from '../utils/context-metadata';
import { AssignmentOptions } from '../hooks/options';
import { Auth } from "../components/Auth";
import { reviewSubmissionScreen, ReviewSubmissionInterface } from "./ReviewSubmissions";
import { useLaunchTokenData } from "../../auth/useTokenData";
import { useGetAssignmentByResource } from '../hooks/resourceLink';
import { ToastProvider, Toasts } from "../../toasts/ToastContext";
import { useAssignmentManagementStates } from "../hooks/grades";
import { useUserRoles } from "../../auth/useAuth";
import { BodyPortalSlotsContext } from '@openstax/ui-components';
import useHelpMenu from '../../components/HelpMenu';
import { useInitializePI } from '../hooks/InitializePI';

const Heading = styled.h1`
  padding: 0;
  margin: 1.5rem 1rem 0 1rem;
  color: #424242;
`;

const Container = styled.div`
  max-width: 80rem;
  margin: 0 auto;
`;

const TextSection = styled.div`
  margin: 0 1rem;
  display: flex;
  flex-direction: column;
`;

const CollapsingSection = styled(({collapsed, ...props}: React.PropsWithChildren<{collapsed: boolean}>) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [height, setHeight] = React.useState<string | undefined>();

  React.useEffect(() => {
    setHeight(collapsed ? '0' : ref.current?.clientHeight ? ref.current?.clientHeight+'px' : undefined);
  }, [collapsed]);

  return <div ref={ref} {...props} {...(height === undefined ? {} : {style: {height}})} />;
})`
  transition: height 0.25s ease-out;
  overflow: hidden;
`;

const formatActivity = (activity: any) => ({
  item: {
    title: activity.title || '',
    text: activity.description,
    icon: activity.activities.length === 1 ? activity.activities[0].icon : undefined,
    url: new URL(renderRouteUrl(launchScreen, {id: activity.id}), window.location.href).href,
    lineItem: { scoreMaximum: 100 }
  },
  editUrl: new URL(renderRouteUrl(editActivityScreen, {id: activity.id}), window.location.href).href,

  // this is not used by lti-gateway, but is included because it can be helpful for local dev / debugging
  reviewUrl: new URL(renderRouteUrl(reviewSubmissionScreen, {assignmentId: activity.id}), window.location.href).href,
});

const useCreateAssignment = () => {
  const apiClient = useApiClient();
  const setAppError = UI.useSetAppError();
  const [state, setState] = React.useState<FetchState<AssignmentCreateResponse, string>>(fetchIdle());

  const save = React.useCallback((
    title: string,
    scope: string,
    activities: CreateAssignmentStepPayload[],
    customOrder: boolean,
    options: AssignmentOptions,
    contextId?: string
  ) => {
    setState(previous => fetchLoading(previous));
    apiClient.apiV0CreateAssignment({
      payload: {title, scope, activities, customOrder, options, ...(contextId ? {contextId} : {})}
    })
      .then(response => response.acceptStatus(201).load())
      .then(response => {
        setState(fetchSuccess(response));

        selectActivity(formatActivity(response));
      })
      .catch(setAppError)
    ;
  }, [apiClient, setAppError]);

  return [state, save] as const;
};

const useEditAssignment = () => {
  const apiClient = useApiClient();
  const setAppError = UI.useSetAppError();
  const [state, setState] = React.useState<FetchState<AssignmentCreateResponse, string>>(fetchIdle());

  const save = React.useCallback((
    id: string,
    activities: CreateAssignmentStepPayload[],
    customOrder: boolean,
    options: AssignmentOptions
  ) => {
    setState(previous => fetchLoading(previous));
    apiClient.apiV0WriteAssignment({
      params: {id},
      payload: {activities, customOrder, options}
    })
      .then(response => response.acceptStatus(201).load())
      .then(response => {
        setState(fetchSuccess(response));

        selectActivity(formatActivity(response));
      })
      .catch(setAppError)
    ;
  }, [apiClient, setAppError]);

  return [state, save] as const;
};

const SelectScope = (params: {setScope: (orn: AnyOrnResource) => void; usedScopes: string[]}) => {
  const featuredScopes = useFrontendConfigValue('featuredScopes');
  const [allBooksClicked, setAllBooksClicked] = React.useState<boolean>(false);
  const featuredScopeOrns = React.useMemo(() => stateHasData(featuredScopes)
    ? featuredScopes.data?.split(',').filter(orn => !!orn)
    : undefined
  , [featuredScopes]);
  const featuredState = useOrns(featuredScopeOrns);
  const launchTokenData = useLaunchTokenData();
  const allBooks = React.useMemo(() =>
    allBooksClicked || (stateHasData(featuredState) && featuredState.data.length < 1)
  , [allBooksClicked, featuredState]);
  const [HelpMenu, ContactFormIframe] = useHelpMenu();

  return <>
    {stateHasData(featuredState)
      ? <>
        {launchTokenData.branding !== false ?
          <>
            <UI.NavBar logo={true}><HelpMenu /></UI.NavBar>
            <ContactFormIframe />
          </>
          : null}
        <Container>
          <Toasts />
          <Heading>Select Content</Heading>
          <CollapsingSection collapsed={allBooks}>
            <TextSection>
              <p>
                Build customized assignments & keep your students engaged with a
                wide range of content and question types in these subjects:
              </p>
            </TextSection>
            <ResourceGrid
              resources={featuredState.data}
              onSelect={params.setScope}
            />
          </CollapsingSection>
          <TextSection>
            <p>
              Assign readings from any OpenStax textbook. Custom assignments and additional
              content will be available in the future for these titles.
            </p>
            {allBooks
              ? <SelectAnyScope setScope={params.setScope} />
              : <UI.ButtonLink
                  style={{alignSelf: 'center'}}
                  onClick={() => setAllBooksClicked(true)}
                >Show All Books</UI.ButtonLink>
            }
          </TextSection>
        </Container>
      </>
      : <UI.Loader />}
  </>;
};

const dateFormat = () =>
  new Date().toLocaleString(undefined, {dateStyle: 'short', timeStyle: 'short'});

const library = 'https://openstax.org/orn/library/en';
const scopeParents = [library];

const SelectAnyScope = (params: {setScope: (orn: AnyOrnResource) => void}) => {
  const libraryState = useOrn(library);
  return <>
    {stateHasData(libraryState) ? <ResourceGrid
      resources={'contents' in libraryState.data ? libraryState.data.contents : []}
      onSelect={params.setScope}
    /> : <UI.Loader />}
  </>;
};

const SelectActivity = () => {
  const [scope, setScope] = React.useState<AnyOrnResource | null>();
  const [createState, createAssignment] = useCreateAssignment();
  const launchTokenData = useLaunchTokenData();
  const [assignmentTitle, setAssignmentTitle] = React.useState(
    launchTokenData?.title || `New Assignment ${dateFormat()}`
  );
  const contextId = typeof launchTokenData.contextId === 'string' ? launchTokenData.contextId : undefined;
  const [contextMetadata, saveContextMetadata] = useContextMetadata(contextId);
  const contextMetadataOrn = useOrn(stateHasData(contextMetadata) ? contextMetadata.data.lastScope : undefined);
  const usedScopes = stateHasData(contextMetadata) ? contextMetadata.data.usedScopes : [];

  React.useEffect(() => {
    if (scope === undefined && stateHasData(contextMetadata) && !contextMetadata.data.lastScope) {
      setScope(null);
    }
  }, [contextMetadata, scope]);

  React.useEffect(() => {
    if (!contextId && scope === undefined) {
      setScope(null);
    }
  }, [contextId, scope]);

  React.useEffect(() => {
    if (stateHasData(contextMetadataOrn) && scope === undefined) {
      setScope(contextMetadataOrn.data);
    }
  }, [contextMetadataOrn, scope]);

  const onCreateSave = (scope: AnyOrnResource)  => (
    flatActivities: CreateAssignmentStepPayload[],
    customOrder: boolean,
    options: AssignmentOptions
    ) => {
      createAssignment(assignmentTitle, scope.orn, flatActivities, customOrder, options, contextId);

      if (stateHasData(contextMetadata)) {
        const lastScope = scope.orn;
        if (!usedScopes.includes(lastScope)) { usedScopes.push(lastScope); }

        saveContextMetadata({
          ...contextMetadata.data,
          lastScope,
          usedScopes,
        });
      }
    };

  return scope === undefined ? <UI.Loader/> :
    scope === null
      ? <SelectScope setScope={setScope} usedScopes={usedScopes}/>
      : <SelectScopeContent
          onUpdateTitle={setAssignmentTitle}
          assignmentTitle={assignmentTitle}
          parents={scopeParents}
          scopeData={scope}
          scope={scope.orn}
          back={() => setScope(null)}
          onSave={onCreateSave(scope)}
          loading={createState.type === FetchStateType.LOADING}
        />;
};

const EditActivity = ({id}: {id: string}) => {
  const assignmentState = useGetAssignmentByResource(id);
  const launchTokenData = useLaunchTokenData();
  const assignmentManagementStates = useAssignmentManagementStates(stateHasData(assignmentState)
    ? assignmentState.data?.id
    : undefined
  );
  const [editState, editAssignment] = useEditAssignment();
  const roles = useUserRoles();
  const [screen, setScreen] = React.useState<'submissions' | 'edit' | undefined>();
  const submissionReviewBetaMember = React.useMemo(() =>
    stateHasData(roles) ? roles.data.includes('submission-review-beta') : undefined
  , [roles]);

  const onEditSave = (assignmentData: AssignmentReadResponse)  => (
    flatActivities: CreateAssignmentStepPayload[],
    customOrder: boolean,
    options: AssignmentOptions
    ) => {
      editAssignment(assignmentData.id, flatActivities, customOrder, options);
  };

  return stateHasData(assignmentState) && submissionReviewBetaMember !== undefined
    ? submissionReviewBetaMember && ['submissions', undefined].includes(screen)
      ? <ReviewSubmissionInterface
          assignment={assignmentState.data}
          assignmentManagementStates={assignmentManagementStates}
          onEditAssignment={() => setScreen('edit')}
      />
      : <SelectScopeContent
        assignmentData={assignmentState.data}
        assignmentTitle={launchTokenData?.title || assignmentState.data.title}
        onShowSubmissions={submissionReviewBetaMember ? () => setScreen('submissions') : undefined}
        assignmentManagementStates={assignmentManagementStates}
        parents={scopeParents}
        scope={assignmentState.data.scope}
        onSave={onEditSave(assignmentState.data)}
        loading={editState.type === FetchStateType.LOADING}
        editView
        showEditToast={editState.type === FetchStateType.SUCCESS}
        isForkedCopy={id !== assignmentState.data.id}
      />
    : <UI.Loader />;
};

const ScreenWrapper = ({ children }: React.PropsWithChildren<{}>) => {
  useInitializePI();

  return <ToastProvider>
    <Auth>
      <BodyPortalSlotsContext.Provider value={['nav', 'root']}>
        {children}
      </BodyPortalSlotsContext.Provider>
    </Auth>
  </ToastProvider>;
};

export const selectActivityScreen = createRoute({name: 'SelectActivity', path: '/instructor/select-activity',
  handler: makeScreen(SelectActivity, ScreenWrapper)
});

export const editActivityScreen = createRoute({name: 'EditActivity', path: '/instructor/edit/:id',
  handler: makeScreen(EditActivity, ScreenWrapper)
});
