/* spell-checker: ignore disabledwhen */
import React from 'react';
import * as UI from '@openstax/ui-components';
import styled from 'styled-components';
import { Options } from '../../hooks/options';
import { Html } from '../Html';
import { Fieldset } from './styles';

const inputComponents = {
  Radio: UI.Forms.Uncontrolled.Radio,
  Checkbox: UI.Forms.Uncontrolled.Checkbox,
  Select: UI.Forms.Uncontrolled.Select,
} as any;

const OptionHeading = styled.h4`
  display: flex;
  align-items: center;
`;

type DynamicInputProps = {
  type: 'Radio' | 'Checkbox' | 'Select';
  props: {
    name?: string;
    label?: string;
    tooltipText?: string;
    disabled?: boolean;
    onChange?: (e: any) => void;
    checked?: boolean;
    value?: string | boolean;
    'data-analytics-input'?: boolean;
  };
};

const DynamicInput = ({type, props}: DynamicInputProps) => {
  const {markdown, default: _, ...inputProps} = props as any;
  return React.createElement(inputComponents[type], inputProps);
};

type OptionProps = {
  option: {[key: string]: any};
  optionGroupState?: Options;
  handleChange: (e: any) => void;
  handleDisabledCheck: (disabler: {optionName: string; optionValue: string | boolean}) => void;
};

export const Option = ({option, optionGroupState, handleChange, handleDisabledCheck}: OptionProps) => {

  /*
   * this variable is true if the option contains only one radio group (all radio
   * inputs of the same name) and therefore the option.title should be used as
   * the fieldset label of the group
   */
  const isOneRadioGroup = React.useMemo(() => {
    let seenRadioName: string | undefined = option.name; // option.name is deprecated
    for (const input of option.optionInputs) {
      if (input.type !== 'Radio') {
        return false;
      }
      if ('name' in input && seenRadioName && input.name !== seenRadioName) {
        return false;
      }
      seenRadioName ??= input.name;
    }

    return true;
  }, [option]);

  const OptionInputs = () =>
    option.optionInputs.map((input: {[key: string]: any}, i: number) => {
      // 'name' on option is deprecated, it should be on the optionInputs
      const name = input.name ?? option.name;

      // radios can treat options like inputs
      if (input.type === 'Radio' && 'options' in input) {
        const optionInput = (option: any, i: number) => {
          const optionDisabled = option.disabledWhen?.optionName && handleDisabledCheck(option.disabledWhen);
          const optionTooltipText = optionDisabled ? option.disabledText : '';

          return <DynamicInput key={option.value} type={input.type} props={{
            name,
            value: option.value,
            label: option.label,
            ...(optionDisabled ? {disabled: optionDisabled, tooltipText: optionTooltipText} : {}),
            onChange: (e: any) => { if (!optionDisabled) handleChange(e); },
            checked: option.value === optionGroupState?.[name]
          }} />;
        };
        // only render the fieldset here if necessary
        return isOneRadioGroup
          ? <React.Fragment key={i}>{input.options.map(optionInput)}</React.Fragment>
          : <Fieldset key={i}><legend>{input.title}</legend>{input.options.map(optionInput)}</Fieldset>;
      } else {
        // check for deprecated value as fallback fallback
        const disabledConfig = input.disabledWhen || input.disabledwhen;

        // map each input from a given activity config to the correct input component
        const disabled = disabledConfig?.optionName && handleDisabledCheck(disabledConfig);

        // because `help` was initially used for "tooltip text when disabled",
        // its not currently possible to have regular inline help text, can
        // revisit this if all providers move to "disabledText" for this case.
        const tooltipText = disabled ? (input.disabledText || input.help) : '';

        return <DynamicInput key={i} type={input.type} props={{
          name,
          value: input.value,
          label: input.label,
          tooltipText,
          disabled,
          onChange: (e: any) => { if (!disabled) handleChange(e); },
          ...(['Checkbox', 'Radio'].includes(input.type)
            ? {checked: input.value === optionGroupState?.[name]}
            : {}),
          ...(input.type === 'Select' ? {value: optionGroupState?.[name]} : {}),
        }} />;
      }
    });

  if (isOneRadioGroup) {
    return <Fieldset>
      <legend>
        <OptionHeading>{option.title} {option.description
          ? <UI.TooltipGroup placement='right'><Html>{option.description}</Html></UI.TooltipGroup>
          : null}
        </OptionHeading>
      </legend>
      <OptionInputs />
    </Fieldset>;
  } else {
    return <>
      <OptionHeading>{option.title} {option.description
        ? <UI.TooltipGroup placement='right'><Html>{option.description}</Html></UI.TooltipGroup>
        : null}
      </OptionHeading>
      <OptionInputs />
    </>;
  }
};
